<template>
	<div id="main-wrapper" class="inner-pages forex-page pamm-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li><router-link to="/white-label">White Label</router-link></li>
						<li class="active"><router-link to="/pamm">PAMM/MAM</router-link></li>
						<!-- <li><router-link to="/ib">Introducing Broker</router-link></li> -->
					</ul>
				</div>
			</div>
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>PAMM/MAM Program </h2>
				<p>A perfect opportunity to earn from the years of experience as a successful trader</p>
			</div>
		</div>
		<div class="pamm-content section">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<p>At AAAFx, we bring golden opportunity for traders to showcase their trading expertise to the world and earn through their followers. This also gives chance to Investors to choose the best traders to trade for them.</p>
				</div>
				<div class="main-img-holder t-center wow fadeIn">
					<img src="assets/images/pamm-img1.webp" alt="AAAFx" title="AAAFx" width="1117" height="144" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInLeft">
					<div class="title-holder">
						<h3>What is PAMM or a MAM account?</h3><br>
						<p>This is a form of pooled fund of investments in which a Money Manager trades on the funds collated by his investor clients. With a single account access, the Money Manager gets to place trades in all his investors’ accounts. In PAMM account, the trades get allocated automatically and instantly in the ratio of the relative equity in the investors’ accounts.  In MAM account, the investor can choose the risk level he wants to take and decide upon the trade allocation accordingly.</p>
					</div>
				</div>
				<div class="img-holder wow fadeInRight">
					<img src="assets/images/pamm-img2.webp" alt="AAAFx" title="AAAFx" width="620" height="513" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="pamm-zulu section">
			<div class="wrapper">
				<div class="title-holder">
					<h3>Why have we combined our PAMM and MAM with ZuluTrade?</h3>
					<br>
					<p>ZuluTrade is synonymous to transparency. When you are pooling funds as an investor and letting some trader trade on your money, you need to have clear visibility of the historic performance of the trader, full flexibility to choose the trade allocations, opportunity to interact with various expert traders, ask them questions, learn from them, etc. We provide you all of these in collaboration with ZuluTrade.</p>
				</div>
				<div class="img-holder t-center">
					<img src="assets/images/pamm-img3.webp" alt="AAAFx" title="AAAFx" width="843" height="175" class="fluid-img" />
				</div>

				<div class="content-holder pamm-man wow fadeInLeft">
					<div class="title-holder">
						<h3>Advantage to PAMM/MAM Manager</h3>
					</div>
					<div class="icon-holder">
						<img src="assets/images/pamm-ico1.webp" alt="AAAFx" title="AAAFx" width="42" height="136" class="fluid-img" />
					</div>
					<div class="text-holder">
						<p>The PAMM/MAM Manager can take advantage of his expertise to earn higher income through the performance fee charged to his clients. Our PAMM/MAM Managers also get full flexibility to set their terms and conditions related to the trading activity and allocation of profits.  Further, the PAMM/MAM Managers also receive highly attractive IB payouts, in addition to performance fees from their clients. </p>
					</div>
				</div>
				<div class="content-holder pamm-investor wow fadeInRight">
					<div class="title-holder">
						<h3>Advantage to Investors</h3>
					</div>
					<div class="icon-holder">
						<img src="assets/images/pamm-ico2.webp" alt="AAAFx" title="AAAFx" width="91" height="125" class="fluid-img" />
					</div>
					<div class="text-holder">
						<p>The Investors get to choose from a huge range of traders listed on ZuluTrade with AAAFx. They can review their trading performance, interact with them before participating in the PAMM or MAM program. Thereafter, they can simply relax and let the Manager do his job. After funding their account and agreeing to a fully transparent Terms and Conditions document, they can continuously monitor their account’s performance. They also have full control over their account in terms of deposits, withdrawals and disconnecting from the Manager account. </p>
					</div>
				</div>
			</div>
		</div>
		<div class="section  t-left">
			<div class="wrapper accordion-holder">
				<h3 v-on:click="pamm ^= true" :class="[{active : pamm}]">Why AAAFx PAMM/MAM program?</h3>
				<div class="content" v-show="pamm">
					<ul class="listing-check">
						<li>Collaboration with Largest Social Trading Network</li>
						<li>Dedicated Account Managers for Investors and Managers</li>
						<li>Flexibility in setting parameters and conditions for the account </li>
						<li>Ultra-low commissions with raw spreads</li>
						<li>Security of funds with a trusted broker </li>
						<li>Small investment required to start</li>
						<li>Transparency for the investors- no hidden clauses</li>
						<li>Full control to investors related to deposits and withdrawals</li>
					</ul>
				</div>
				<h3 v-on:click="requirements1 ^= true" :class="[{active : requirements1}]">Minimum requirements</h3>
				<div class="content" v-show="requirements1">
					<p></p>
					<ul class="listing-check">
						<li>Total deposit must be a minimum of $5,000. </li>
						<li>Minimum 4 sub accounts required.  </li>
						<li>Each investor should sign-up the POA and agree with all the conditions.  </li>
					</ul>
				</div>
				<h3 v-on:click="requirements2 ^= true" :class="[{active : requirements2}]">How to Sign up?</h3>
				<div class="content" v-show="requirements2">
					<ul class="listing-check">
						<li>Sign-up on the AAAFx portal.  </li>
						<li>Build up a team of investors.  </li>
						<li>Get the Investors sign the POA.  </li>
						<li>Start earning profits. </li>
					</ul>
				</div>
				<div class="section-small">
					<div class="title-holder">
						<h6>Our PAMM/MAM program is highly customizable, hence get in touch with us to know more and to start a <br>PAMM/MAM account. Contact Us now here  or write to us at <a href="mailto:support@aaafx.com.">support@aaafx.com.</a> </h6>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>
<script>
	export default {
  name: "forex",
  data(){
		return{
			pamm:false,
			requirements1:false,
			requirements2:false,
			
		}
  },
}
</script>